import 'core-js/stable'
import "regenerator-runtime/runtime";
import Vue from 'vue'
import CoreuiVue from '@coreui/vue'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import firebase from '@firebase/app';
require('@firebase/auth');
require('@firebase/firestore');
require('@firebase/storage');
require('@firebase/functions');
import store from './store'

Vue.config.productionTip = false

Vue.use(CoreuiVue)

Vue.use(VueAxios, axios)

var config = {}
if (process.env.NODE_ENV == 'staging') {
  config = {
    apiKey: "AIzaSyC_tYJLCAgfLsPMF-bQ9EW3I32gGTMIucg",
    authDomain: "oc-staging-135ba.firebaseapp.com",
    databaseURL: "https://oc-staging-135ba.firebaseio.com",
    projectId: "oc-staging-135ba",
    storageBucket: "oc-staging-135ba.appspot.com",
    messagingSenderId: "738790314034",
    appId: "1:738790314034:web:781f9964c955e37cb22c2b",
    measurementId: "G-2P9Z0RE7T4"
  }
} else if (process.env.NODE_ENV == 'production') {
  config = {
    apiKey: "AIzaSyDbsdO4bbeA2fdhZq8FuN0yclYGDoOavz8",
    authDomain: "oc-production-9577d.firebaseapp.com",
    databaseURL: "https://oc-production-9577d.firebaseio.com",
    projectId: "oc-production-9577d",
    storageBucket: "oc-production-9577d.appspot.com",
    messagingSenderId: "558412982578",
    appId: "1:558412982578:web:55fe33a8b842ae4cee8f2b",
    measurementId: "G-KS8DKV3L6Z"
  }
}
firebase.initializeApp(config);
// functionsをローカルで確認する場合
//firebase.app().functions('asia-northeast1').useEmulator("localhost", 5001);

new Vue({
  el: '#app',
  router,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: '<App/>',
  components: {
    App
  },
  store
})
