import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
      isLoading: false
    },
    mutations: {
      showLoading (state) {
        state.isLoading = true
      },
      hideLoading (state) {
        state.isLoading = false
      },
    }
  })