import Vue from 'vue'
import Router from 'vue-router'
import firebase from '@firebase/app';

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')


// Views - Components
const Cards = () => import('@/views/base/Cards')
const Switches = () => import('@/views/base/Switches')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tables = () => import('@/views/tables/Tables')
const AdvancedTables = () => import('@/views/tables/AdvancedTables')
const Tooltips = () => import('@/views/base/Tooltips')
const MakersTables = () => import('@/views/accounts/MakersTables')
const DistributorsTables = () => import('@/views/accounts/DistributorsTables')
const TestTables = () => import('@/views/tables/TestTables')



// Views - Forms
const BasicForms = () => import('@/views/forms/Forms')
const ValidationForms = () => import('@/views/forms/ValidationForms')
const ImportForms = () => import('@/views/forms/ImportForms')
const ImportStoreForms = () => import('@/views/forms/ImportStoreForms')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')


// Views - Notifications
const Report = () => import('@/views/report/Report')


// StoreCoupons
const StoreCoupons = () => import('@/views/stores/StoreCoupons')

// StoreMessage
const StoreMessage = () => import('@/views/stores/StoreMessage')

// OcClosing
const OcClosing = () => import('@/views/closing/OcClosing')
// UserClosing
const UserClosing = () => import('@/views/closing/UserClosing')

// Views - Apibatch
const Apibatch = () => import('@/views/apibatch/Apibatch')

// Views - Command
const Command = () => import('@/views/develop/Command')

// Views - Statistics
const Statistics = () => import('@/views/develop/Statistics')

// Info
const Info = () => import('@/views/info/Info')

// Notice
const TargetSegmentForm = () => import('@/views/notices/TargetSegmentForm')

Vue.use(Router)


/* router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let currentUser = firebase.auth().currentUser
  if (requiresAuth) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    if (!currentUser) {
      next({
        path: '/pages/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
}) */




let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: { requiresAuth: true },
        },
        {
          path: 'accounts',
          redirect: '/accounts/tables/makers',
          name: 'アカウント管理',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: 'new',
              name: 'アカウント登録',
              component: ValidationForms
            },
            {
              path: 'tables/:authority',
              name: 'アカウント一覧',
              component: MakersTables
            },
            {
              path: ':id',
              name: 'アカウント編集',
              component: ValidationForms
            },
          ]
        },
        {
          path: 'stores',
          redirect: '/',
          name: '店舗別クーポン管理',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: ':id/coupons',
              name: 'クーポン選択',
              component: StoreCoupons,
            },
          ]
        },
        {
          path: 'coupons',
          redirect: '/coupons',
          name: 'クーポン管理',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: '',
              name: 'クーポン一覧',
              component: AdvancedTables
            },
            {
              path: 'new',
              name: 'クーポン登録',
              component: BasicForms
            },
            {
              path: 'import',
              name: 'クーポン取込',
              component: ImportForms
            },
            {
              path: 'import-store',
              name: 'クーポン店舗取込',
              component: ImportStoreForms
            },
            {
              path: ':id',
              name: 'クーポン編集',
              component: BasicForms
            },
          ]
        },
        {
          path: 'report',
          redirect: '/report/report',
          name: '実績管理',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: 'report',
              name: '実績レポート',
              component: Report
            },
          ]
        },
        {
          path: 'closing',
          redirect: '/',
          name: '確定処理',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: 'oc-closing',
              name: 'OC確定処理',
              component: OcClosing,
            },
            {
              path: 'user-closing',
              name: '月次確定処理',
              component: UserClosing,
            },
          ]
        },
        // {
        //   path: 'apibatch',
        //   redirect: '/apibatch/apibatch',
        //   name: '夜間バッチ',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   meta: { requiresAuth: true },
        //   children: [
        //     {
        //       path: 'apibatch',
        //       name: 'バッチ処理状況',
        //       component: Apibatch 
        //     },
        //   ]
        // },
        {
          path: 'message',
          redirect: '/message/storemessage',
          name: 'メッセージ',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: 'storemessage',
              name: '店舗メッセージ送信',
              component: StoreMessage 
            },
          ]
        },
        {
          path: 'info',
          redirect: '/',
          name: 'お知らせ',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: 'info',
              name: 'お知らせ登録',
              component: Info,
            },
          ]
        },
        {
          path: 'notices',
          redirect: '/',
          name: 'PUSH通知',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: 'targetsegment/new',
              name: 'ターゲットPUSH通知',
              component: TargetSegmentForm,
            },
          ]
        },
        {
          path: 'develop',
          redirect: '/develop/develop',
          name: '開発者',
          component: {
            render (c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: 'command',
              name: 'APIコマンド作成',
              component: Command
            },
            {
              path: 'statistics',
              name: '統計資料',
              component: Statistics
            },
          ]
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        localStorage.setItem('uid', user.uid)
        localStorage.setItem('email', user.email)
        next()
      } else {
        next({
          path: '/pages/login',
          query: { redirect: to.fullPath }
        })
      }
    })
  } else {
    next() 
  }
})

export default router